import { Injectable, inject, signal } from '@angular/core';
import { BehaviorSubject, map, Observable, Subject, tap } from 'rxjs';
import { CCDropdownModelSingle, CCPostModel, DynamicFormConfig } from '../types/dynamic-form.types';
import { DataManager, WebApiAdaptor } from '@syncfusion/ej2-data';
import { ApiHttpService, ILayoutConfig, LayoutService } from '@itm/services';

@Injectable({
  providedIn: 'root'
})
export class DynamicFormService {
  private readonly layoutService = inject(LayoutService);
  private readonly _http = inject(ApiHttpService);
  //TODO task1
  // LayoutConfigSig! = this.layoutService.LayoutConfigSig;
  LayoutConfigSig = signal<ILayoutConfig>(undefined);
  //TODO get from route
  constructor() { }


  getDropdownRemoteDataEJS(remoteUrl: string): DataManager {
    // bind the DataManager instance to dataSource property
    const url = `${this._http.GET_API_URL()}${remoteUrl}`;
    return new DataManager({
      url: url,
      adaptor: new WebApiAdaptor,
      crossDomain: true,

    });
  }
  getDropdownRemoteDataEJS2(remoteUrl: string): Observable<any> {
    // bind the DataManager instance to dataSource property
    const url = `${this._http.GET_API_URL()}${remoteUrl}`;

   return this._http.GETT<any>(remoteUrl)
    .pipe(
      tap(x=>console.log('ooo',x)),
      map(x=>x.data.data['result']),
      tap(x=>console.log(x))
    );

    
  }
  getDropdownRemoteCustomCategoryDataEJS(remoteUrl: string): DataManager {
    // bind the DataManager instance to dataSource property
    const url = `${remoteUrl}`;
    console.log('url', url);

    const staticUrl = 'http://localhost:3000/customCategory'

    return new DataManager({
      url: staticUrl,
      adaptor: new WebApiAdaptor,
      crossDomain: true
    });
  }



  // get dynamicFormConfig$(): Observable<DynamicFormConfig> {
  //   return this._dynamicFormConfig.asObservable();
  // }

  // getConfig(key:string): Observable<DynamicFormConfig> {
  //   //return this._httpClient.get<DynamicFormConfig>(`${this._url_config}/${key}`)
  //   return this.#http.get<DynamicFormConfig>(`${this._url_config}`)
  //     .pipe(
  //       tap((response: DynamicFormConfig) => {          
  //         this._dynamicFormConfig.next(response);
  //       }),
  //     );
  // }
  upsert<T>(url: string, data: any) {
    if (data.id) {
      return this._http.PUT<T>(`${url}/${data.id}`, data);
    }
    return this._http.POST<T>(url, data);
  }
  getById(url: string, id: string) {
    const entityUrl = url + '/' + id;
    return this._http.GETT(entityUrl)
  }

  // // selectedEntity = signal<SelectedEntityParam | undefined>(undefined);
  // // entitySelected(selectedEntity: SelectedEntityParam): void {
  // //   this.selectedEntity.set(selectedEntity);
  // // }
  // // private entity$ = toObservable(this.selectedEntity)
  // // .pipe(
  // //   filter(Boolean),
  // //   switchMap(selectedEntity => {
  // //     const entityUrl = selectedEntity.getEntityEndpoint + '/' + selectedEntity.selectedEntityId;
  // //     return this.#http.GET(entityUrl)
  // //   }),
  // //   map(x=>{
  // //     if (!x.error) {
  // //       return of(x.data);
  // //     }
  // //   })
  // // );
  // // entitySig = toSignal(this.entity$);



  // getEntity(url:string,id){
  //   return this.#http.GET(`${url}/${id}`);
  // }



  // flattenObject9(obj, parent = '', res = {}) {
  //   for (let key in obj) {
  //     if (Object.prototype.hasOwnProperty.call(obj, key)) {
  //       const propName = parent ? `${parent}.${key}` : key;
  //       if (typeof obj[key] === 'object' && obj[key] !== null && !Array.isArray(obj[key])) {
  //         this.flattenObject9(obj[key], propName, res);
  //       } else {
  //         res[propName] = obj[key];
  //       }
  //     }
  //   }
  //   return res;
  // }
  // flattenObject8(obj) {
  //   const flattened = {};
  //   for (const [key, value] of Object.entries(obj)) {
  //     if (typeof value === "object" && value !== null) {
  //       // Spread nested object properties directly into flattened object
  //       Object.assign(flattened, ...Object.entries(value));
  //     } else {
  //       // Add key-value pair to flattened object
  //       flattened[key] = value;
  //     }
  //   }
  //   return flattened;
  // }
  // flattenObject2(obj, depth = Infinity) {
  //   const flattened = {};
  //   const queue = [{ key: "", value: obj, depth: 0 }];

  //   while (queue.length) {
  //     const { key, value, depth } = queue.shift();

  //     if (depth === 0 || typeof value !== "object" || value === null) {
  //       flattened[key] = value;
  //     } else if (depth < Infinity) {
  //       // Process nested objects only if depth limit not reached
  //       for (const [nestedKey, nestedValue] of Object.entries(value)) {
  //         queue.push({ key: `${key ? `${key}.` : ""}${nestedKey}`, value: nestedValue, depth: depth + 1 });
  //       }
  //     }
  //   }

  //   return flattened;
  // }
  // flattenObject3(obj, prefix = "") {
  //   const flattened = {};
  //   for (const [key, value] of Object.entries(obj)) {
  //     const newKey = prefix ? `${prefix}.${key}` : key;
  //     if (typeof value === "object" && value !== null) {
  //       // Recursively flatten nested objects
  //       Object.assign(flattened, this.flattenObject3(value, newKey));
  //     } else {
  //       // Add key-value pair to flattened object
  //       flattened[newKey] = value;
  //     }
  //   }
  //   return flattened;
  // }
  // flattenObject4(obj) {
  //   const flattened = {};
  //   for (const [key, value] of Object.entries(obj)) {
  //     if (typeof value === "object" && value !== null) {
  //       // Spread nested object properties directly into flattened object
  //       Object.assign(flattened, ...Object.entries(value));
  //     } else {
  //       // Add key-value pair to flattened object
  //       flattened[key] = value;
  //     }
  //   }
  //   return flattened;
  // }
  mergeNestedObjects(input) {
    const output = {};
    for (const key in input) {
      if (input.hasOwnProperty(key) && typeof input[key] === 'object') {
        Object.assign(output, input[key]);
      }
    }
    return output;
  }

  getCustomCategory$(module: string, key: string): Observable<any> {
    //return this.#http.GET(`${this.#http.GET_API_URL()}/${key}/options`);
    const url = `${module}/custom-category/${key}/dropdown`;
    const ccOptions = this._http.GETT<CCDropdownModelSingle[]>(url);
    return ccOptions;
    //return this.http.GET(`http://localhost:7077/api/${module}/custom-category/${key}/dropdown`);
  }

  editTitle = signal<string>('');
  setEditTitle(editTitle: string) {
    this.editTitle.set(editTitle);
  }

  private _entityData = new BehaviorSubject<any>(null);
  entityData$ = this._entityData.asObservable();

  setEntityData(btn: any) {
    this._entityData.next(btn);
  }

  private _reloadFormData = new BehaviorSubject<any>(null);
  reloadFormData$ = this._reloadFormData.asObservable();

  reload(action: any) {
    this._reloadFormData.next(action);
  }

}
